.body {
  @container (width < 720px) {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}

.media-wrapper {
  @container (width < 720px) {
    max-width: 100%;
  }
}
