.wrapper {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  @container (width < 880px) {
    gap: 2rem;
  }

  @container (width < 650px) {
    gap: 1rem;
    font-size: 0.75rem;
  }

  @container (width < 560px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    font-size: 1rem;
    padding-left: 2.5rem;
  }
}
